// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-page-templates-announcements-announcements-connected-js": () => import("./../../../src/page-templates/Announcements/Announcements.connected.js" /* webpackChunkName: "component---src-page-templates-announcements-announcements-connected-js" */),
  "component---src-page-templates-front-page-front-page-connected-js": () => import("./../../../src/page-templates/FrontPage/FrontPage.connected.js" /* webpackChunkName: "component---src-page-templates-front-page-front-page-connected-js" */),
  "component---src-page-templates-mockpage-mockpage-js": () => import("./../../../src/page-templates/Mockpage/Mockpage.js" /* webpackChunkName: "component---src-page-templates-mockpage-mockpage-js" */),
  "component---src-page-templates-page-page-connected-js": () => import("./../../../src/page-templates/Page/Page.connected.js" /* webpackChunkName: "component---src-page-templates-page-page-connected-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

